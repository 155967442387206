import React, { PureComponent } from "react"

/* Check internet connection */
import { ReactInternetSpeedMeter } from 'react-internet-meter'


class Internet extends PureComponent {

    render = () => {
        const { setInternetSpeed, url } = this.props

        return (
            <ReactInternetSpeedMeter
                txtSubHeading="Internet is too slow"
                outputType="alert"
                customClassName={null}
                txtMainHeading="Opps..."
                pingInterval={5000} // milliseconds 
                thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
                threshold={100}
                imageUrl={url}
                downloadSize="1781287"  //bytes
                callbackFunctionOnNetworkDown={(speed) => { }}
                callbackFunctionOnNetworkTest={(speed) => setInternetSpeed(speed)}
            />
        )

    }
}

export default Internet