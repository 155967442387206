import React, { Component } from "react"

/* Constants */
import { utils, closed } from '../constants'

/* Picture */
import { Card } from "../components"

/* Widget Player Cards */
class PlayerCards extends Component {

    /* Draw Result */
    _result = () => {

        /* Fields */
        const { game } = this.props

        if (game) {

            if (game.level === 0) {
                return <div className="player-game-status red">{utils.translate('NO GAME')}</div>
            }

            if (game.level > 0) {

                const text = `${utils.translate(game.name)}${game.hasAceKing ? ` + ${utils.translate("ACE KING")}` : ""}`
                let size = "default"
                if (text.length > 20) {
                    size = "smaller"
                }

                return <div className={`player-game-status green ${size}`}>{text}</div>
            }

        }

    }

    /* Card status */
    status = card => {

        const { game } = this.props

        let status = "player"

        if (game && game.data) {
            const index = game.data.findIndex(e => parseInt(e.id) === parseInt(card.id))
            if (index > -1) {
                if (game.data[index].status) {
                    status = "animated"
                }
            }
        }

        return status
    }


    render = () => {

        const { cards, solution, box, status, ante } = this.props
        const playerCards = utils.getUniqueValues(cards, "uuid")

        return (
            <div className="player-container" style={{ opacity: status === "CHOICE" || solution === "FOLD" || (status !== "CHOICE" && ante === 0) ? 0.3 : 1 }}>

                <div className="player-container-head">
                    <div className="player-container-title">{utils.translate("BOX")} {box + 1}</div>
                    {this._result()}
                </div>

                <div className={"player-cards"}>
                    {playerCards.map(card =>
                        <div className="player-card" key={`p-${card.uuid}`}>
                            <div className="player-flip-card open">
                                <div className="player-flip-card-inner">
                                    <div className="player-flip-card-front">
                                        <img src={closed.image} alt="Closed" />
                                    </div>
                                    <div className="player-flip-card-back">
                                        <div className={`${this.status(card)}`}>
                                            <Card data={card.name} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="player-spaces">
                    {[1, 2, 3, 4, 5].map(key =>
                        <div key={`${key}`} className="player-space">
                            <div className="player-space-circle">
                                <div className="player-space-diamond"></div>
                            </div>
                            <div className="player-space-round" />
                        </div>
                    )}
                </div>

            </div >
        )
    }

}

export default PlayerCards